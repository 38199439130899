<template>
  <div class="under-maintenance-page">
    <img :src="icon" alt="" class="icon" />
    <div class="description">
      {{ description }}
    </div>
  </div>
</template>
<script>
export default {
  name: "UnderMaintenancePage",
  data() {
    return {
      icon: require("@/assets/images/icons/underMaintenance.svg"),
      description: null,
    };
  },
  mounted() {
    this.getMyDescription();
  },
  methods: {
    async getMyDescription() {
      try {
        const locale = this.$i18n.locale;
        const response = await this.$http.get("web/settings", {
          params: {
            key: `maintenance_message_${locale}`,
          },
        });
        if (response.status === 200 || response.status === 201) {
          this.description = response.data.data.value.value;
        }
      } catch (error) {
        this.$helpers.handleError(error);
      }
    },
  },
};
</script>
<style lang="scss">
@import "./_index";
</style>
